.custom-toggle-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.toggle-label {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.toggle-button {
  padding: 5px 25px;
  border: 3px solid #05b49b;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 900;
  font-size: 14px;
  background-color: white;
  transition: background-color 0.3s, border-color 0.3s;
}

.selected {
  background-color: #05b49b;
  border-color: #05b49b;
  color: white;
}

.toggle-button.disabled {
  color: grey;
  pointer-events: none;
}
